@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #036307;
  --text-color: #00300e;
  --accent-color: #609f63;
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--text-color);
}
