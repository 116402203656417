@media screen and (min-width: 360px) {
  .Footer {
    background-color: var(--text-color);
    color: white;
    padding: 20px;
    margin: 0 auto;

    div {
      margin: 25px 0;

      :nth-child(even) {
        margin: 10px 0;
      }
    }

    .copyright {
      text-align: center;
      margin: 75px 0 15px;
    }

    .link-style {
      color: wheat;
      text-decoration: none;
    }

    .footer-item {
      .footerLinks {
        display: flex;
        color: white;
        text-decoration: none;
      }
      nav a:hover {
        color: wheat;
      }
    }

    .contact-footer {
      a {
        text-decoration: none;
        color: wheat;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Footer {
    .address {
      width: 75%;
    }
  }
}

@media screen and (min-width: 1024px) {
  .Footer {
    .groupFooter {
      max-width: 85%;
      margin: 0 auto;
    }
    .groupFooterItem {
      display: flex;
      gap: 10px;
      margin: 60px 0;

      .footer-item {
        width: 35%;
        max-width: 80%;
        margin: 0 auto;
      }

      .address {
        p {
          width: 60%;
        }
      }
    }
  }
}
