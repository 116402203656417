@media screen and (min-width: 360px) {
  header {
    background-color: #eee;
    position: fixed;
    top: 0;
    width: 100%;
  }
  .head {
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 10px 0;
    max-width: 90%;
    position: relative;
  }

  .menu {
    display: block;
    position: absolute;
    right: 5%;
    font-size: 25px;
  }

  .navLink {
    display: none;
  }

  .navLink-expanded {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: var(--text-color);
    padding: 25px;
    width: 100%;
    top: 0;
    margin: 70px 0;

    .anchor {
      display: block;
      text-align: center;
      padding: 10px;
      margin: 15px 0;
      color: white;
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .menu {
    display: none;
    position: absolute;
  }

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 10px 0;
    max-width: 90%;
  }

  .navLink {
    display: flex;
    align-items: flex-end;
    margin-left: auto;
  }
  .anchor {
    color: var(--text-color);
    text-decoration: none;
    margin: 0 15px 0 0;
    padding: 5px;
    align-self: center;
  }

  #contactUs {
    background-color: var(--text-color);
    color: white;
    border-radius: 15px;
    padding: 10px 20px;
  }
}

@media screen and (min-width: 1024px) {
  .anchor {
    margin: 0 55px 0 0;
    align-self: center;
  }

  #contactUs {
    background-color: var(--text-color);
    color: white;
    border-radius: 15px;
    padding: 10px 20px;
  }
}
