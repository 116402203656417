@media screen and (min-width: 360px) {
  .Features {
    background-color: #609f63;
    color: white;
    padding: 15px;
    margin: 95px 0;
    text-align: center;

    h3 {
      margin: 20px;
    }

    .eachFeature {
      margin: 45px auto;
      width: 75%;

      h4 {
        margin: 10px 0;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .Features {
    padding: 60px 40px;
    h3 {
      font-size: 1.5rem;
      margin: 40px 0;
    }

    .groupFeature {
      display: flex;

      .eachFeature {
        width: 60%;
        p {
          // width: 75%;
        }
      }
    }
  }
}
