@media screen and (min-width: 360px) {
  .Call2Action {
    text-align: center;
    margin: 125px auto;
    max-width: 75%px;

    h4 {
      margin: 25px 0;
    }

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 25px 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .Call2Action {
    max-width: 60%;

    h4 {
      font-size: 1.5rem;
    }
  }
}
