@media screen and (min-width: 360px) {
  .BusinessSuite {
    background-color: var(--accent-color);
    padding: 20px;
    margin: 50px 0 20px;
    color: white;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas:
      "item1 item2"
      "item3 item3";

    .item1 {
      grid-area: item1;
      text-align: center;
      margin: 25px 0;
    }

    .item2 {
      grid-area: item2;
      text-align: center;
      margin: 25px 0;
    }

    .item3 {
      grid-area: item3;
      justify-self: center;
      text-align: center;
      margin: 25px 0;
    }

    p {
      font-size: 50px;
    }

    h4 {
      margin: 10px 0;
    }
  }
}

@media screen and (min-width: 728px) {
  .BusinessSuite {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-template-areas: "item1 item2 item3";
  }
}
