// mobile media query
@media screen and (min-width: 360px) {
  .heroSection {
    margin: 150px auto 40px;
    max-width: 90%;
    // opacity: 0;

    > p {
      margin: 25px 0;
    }

    #h1 {
      color: var(--text-color);
    }
  }

  .one {
    width: 100%;
    height: 180px;
    object-fit: cover;
    grid-area: one;
  }

  .two {
    width: 100%;
    grid-area: two;
    height: 180px;
    object-fit: cover;
  }

  .three {
    width: 100%;
    grid-area: three;
    height: max-content;
    height: 180px;
    object-fit: cover;
  }

  .four {
    width: 100%;
    height: 180px;
    object-fit: cover;
    grid-area: four;
  }

  .image-container {
    max-width: 90%;
    margin: 95px auto;
    display: grid;
    grid-template-columns: auto auto;
    gap: 5px;
    grid-template-areas:
      "one one"
      "two three"
      "four four";
  }
}

// desktop media query
@media screen and (min-width: 1024px) {
  .hero {
    display: flex;
    margin: 0 20px;

    .heroSection {
      width: 40%;
      margin: 205px 0;
      padding: 0 0 0 40px;

      > p {
        margin: 25px 0;
        width: 60%;
      }
    }

    .image-container {
      width: 50%;
      display: grid;
      grid-template-columns: auto auto;
      gap: 10px;
      grid-template-areas:
        "three one"
        "three two"
        "three four";
    }

    .one {
      width: 100%;
      height: 180px;
      object-fit: cover;
      grid-area: one;
    }

    .two {
      width: 100%;
      grid-area: two;
      object-fit: cover;
    }

    .three {
      width: 100%;
      grid-area: three;
      height: 560px;

      object-fit: cover;
    }

    .four {
      width: 100%;
      height: 180px;
      object-fit: cover;
      grid-area: four;
    }
  }
}
