//AboutUs page styling
@media screen and (min-width: 360px) {
  .about-us {
    text-align: center;
    margin: 85px 0;
    padding: 20px;

    h1 {
      margin: 40px auto;
    }

    p {
      width: 65%;
      margin: 40px auto;
    }
  }

  .about-us-div {
    margin: 100px auto;
    .about-us-section {
      margin: 20px auto;
      max-width: 90%;

      h4 {
        margin: 20px 0;
      }
      ul {
        margin: 20px;
      }
    }
  }

  // Error page styling mobile
  .error-page {
    margin: 30%;
    display: flex;
    .error-page-item {
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }

  //FAQs page styling mobile
  .Faqs {
    margin: 0 auto 140px;
    max-width: 90%;

    .main {
      margin: 50px auto;
    }

    .Faqs-text {
      h1 {
        margin: 30px 0;
      }
    }
  }

  //Contact Us page styling mobile
  .contact-us {
    .contact-info {
      margin: 0 auto;
      max-width: 90%;
    }
    .contact {
      margin: 2px 0;

      .contact-us-text {
        max-width: 90%;
        margin: 0 auto 80px;
      }
    }

    .location {
      margin: 25px 0;

      p {
        width: 75%;
      }
    }

    .emails {
      margin: 25px 0;
    }

    .openingHours {
      margin: 25px 0;
    }

    .telephone {
      margin: 25px 0;
    }

    h4 {
      margin: 5px 0;
    }

    .message {
      margin: 10px auto 80px;
      max-width: 80%;

      .message-us {
        text-align: center;
        margin: 85px 0 45px;
      }
    }

    form {
      .form-item {
        margin: 10px 0;

        input {
          width: 100%;
          margin: 5px 0;
          padding: 12px 20px;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid #609f63;
        }

        textarea {
          width: 100%;
          height: 100px;
          margin: 5px 0;
          box-sizing: border-box;
          border: none;
          border-bottom: 2px solid #609f63;
        }
      }

      input[type="submit"] {
        width: 40%;
        margin: 0 auto;
        text-align: center;
        padding: 10px 40px;
        border: none;
        border-radius: 15px;
        background-color: var(--primary-color);
        color: white;
      }
    }
  }
}

// Tablet styling
@media screen and (min-width: 768px) {
  //FAQs page styling
  .Faqs {
    .main {
      margin: 50px auto;
    }

    .Faqs-text {
      h1 {
        margin: 30px 0;
      }
    }
  }

  // About us
  .about-us {
    h1 {
      margin: 40px auto;
    }

    p {
      width: 65%;
      margin: 40px auto;
    }
  }

  .about-us-div {
    h4 {
      font-size: 1.5rem;
      text-align: center;
    }
  }
}

//Desktop styling
@media screen and (min-width: 1024px) {
  // About us
  .about-us {
    width: 75%;
    margin: 100px auto;

    h1 {
      margin: 40px auto;
    }

    p {
      width: 65%;
      margin: 40px auto;
    }
  }
  .about-us-div {
    position: relative;
    margin: 20px 0 500px;

    img {
      height: 500px;
      object-fit: cover;
    }

    .about-us-section {
      position: absolute;
      background-color: white;
      padding: 25px 35px;
      box-shadow: 5px 10px 20px -5px;
      top: 80%;
      left: 15%;
      width: 65%;
      margin: 0 auto;
      h4 {
        font-size: 1.5rem;
        text-align: center;
        margin: 60px;
      }
    }
  }

  // FAQs page
  .main {
    display: flex;
    gap: 10px;
  }

  // Contact us page
  .contact-us {
    position: relative;
    margin: 75px 0;

    .contact {
      position: relative;
      margin: 0 0 700px;
    }
    .message {
      padding: 25px;
      background-color: white;
      box-shadow: 5px 10px 20px -5px;

      form {
        display: grid;

        input[type="message"] {
          margin: 0 auto;
        }
      }
    }
    .contact-flex-container {
      position: absolute;
      top: 70%;
      left: 5%;
      background-color: white;
      box-shadow: 5px 10px 20px -10px;
      display: flex;
      gap: 0 30px;
      width: 80%;
      margin: 0 auto;
      padding: 50px;

      .contact-info-flex-container {
        display: flex;

        .contact-info-flex-item {
          padding: 25px;
        }
      }
    }
  }
}
