@media screen and (min-width: 360px) {
  .briefBackground {
    max-width: 90%;
    margin: 125px auto;
    h2 {
      margin: 35px 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .briefBackground {
    max-width: 80%;

    h2 {
      text-align: center;
      margin: 55px 0;
    }

    .briefContainer {
      display: flex;
      gap: 10px;
    }
  }
}
